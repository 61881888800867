exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-our-process-js": () => import("./../../../src/pages/about/our-process.js" /* webpackChunkName: "component---src-pages-about-our-process-js" */),
  "component---src-pages-about-partners-js": () => import("./../../../src/pages/about/partners.js" /* webpackChunkName: "component---src-pages-about-partners-js" */),
  "component---src-pages-about-sectors-js": () => import("./../../../src/pages/about/sectors.js" /* webpackChunkName: "component---src-pages-about-sectors-js" */),
  "component---src-pages-about-testimonials-js": () => import("./../../../src/pages/about/testimonials.js" /* webpackChunkName: "component---src-pages-about-testimonials-js" */),
  "component---src-pages-hyva-agency-js": () => import("./../../../src/pages/hyva-agency.js" /* webpackChunkName: "component---src-pages-hyva-agency-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-insights-prismic-article-uid-js": () => import("./../../../src/pages/insights/{prismicArticle.uid}.js" /* webpackChunkName: "component---src-pages-insights-prismic-article-uid-js" */),
  "component---src-pages-magento-agency-developer-js": () => import("./../../../src/pages/magento-agency/developer.js" /* webpackChunkName: "component---src-pages-magento-agency-developer-js" */),
  "component---src-pages-magento-agency-js": () => import("./../../../src/pages/magento-agency.js" /* webpackChunkName: "component---src-pages-magento-agency-js" */),
  "component---src-pages-magento-agency-support-js": () => import("./../../../src/pages/magento-agency/support.js" /* webpackChunkName: "component---src-pages-magento-agency-support-js" */),
  "component---src-pages-shopify-agency-developer-js": () => import("./../../../src/pages/shopify-agency/developer.js" /* webpackChunkName: "component---src-pages-shopify-agency-developer-js" */),
  "component---src-pages-shopify-agency-js": () => import("./../../../src/pages/shopify-agency.js" /* webpackChunkName: "component---src-pages-shopify-agency-js" */),
  "component---src-pages-shopify-agency-support-js": () => import("./../../../src/pages/shopify-agency/support.js" /* webpackChunkName: "component---src-pages-shopify-agency-support-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-prismic-work-blog-post-uid-js": () => import("./../../../src/pages/work/{prismicWorkBlogPost.uid}.js" /* webpackChunkName: "component---src-pages-work-prismic-work-blog-post-uid-js" */)
}

